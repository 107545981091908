/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind&family=Work+Sans:wght@600&display=swap');

@font-face {
  font-family: "Greycliff CF";
  src: url("../asset/fonts/GreycliffCF-Bold.eot");
  src: local("Greycliff CF Bold"), local("../asset/fonts/GreycliffCF-Bold"),
    url("../asset/fonts/GreycliffCF-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../asset/fonts/GreycliffCF-Bold.woff2") format("woff2"),
    url("../asset/fonts/GreycliffCF-Bold.woff") format("woff"),
    url("../asset/fonts/GreycliffCF-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Greycliff CF";
  src: url("../asset/fonts/GreycliffCF-Light.eot");
  src: local("Greycliff CF Light"), local("../asset/fonts/GreycliffCF-Light"),
    url("../asset/fonts/GreycliffCF-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../asset/fonts/GreycliffCF-Light.woff2") format("woff2"),
    url("../asset/fonts/GreycliffCF-Light.woff") format("woff"),
    url("../asset/fonts/GreycliffCF-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Greycliff CF";
  src: url("../asset/fonts/GreycliffCF-Heavy.eot");
  src: local("Greycliff CF Heavy"), local("../asset/fonts/GreycliffCF-Heavy"),
    url("../asset/fonts/GreycliffCF-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../asset/fonts/GreycliffCF-Heavy.woff2") format("woff2"),
    url("../asset/fonts/GreycliffCF-Heavy.woff") format("woff"),
    url("../asset/fonts/GreycliffCF-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Greycliff CF";
  src: url("../asset/fonts/GreycliffCF-Thin.eot");
  src: local("Greycliff CF Thin"), local("../asset/fonts/GreycliffCF-Thin"),
    url("../asset/fonts/GreycliffCF-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../asset/fonts/GreycliffCF-Thin.woff2") format("woff2"),
    url("../asset/fonts/GreycliffCF-Thin.woff") format("woff"),
    url("../asset/fonts/GreycliffCF-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Greycliff CF";
  src: url("../asset/fonts/GreycliffCF-Medium.eot");
  src: local("Greycliff CF Medium"), local("../asset/fonts/GreycliffCF-Medium"),
    url("../asset/fonts/GreycliffCF-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../asset/fonts/GreycliffCF-Medium.woff2") format("woff2"),
    url("../asset/fonts/GreycliffCF-Medium.woff") format("woff"),
    url("../asset/fonts/GreycliffCF-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Greycliff CF";
  src: url("../asset/fonts/GreycliffCF-Regular.eot");
  src: local("Greycliff CF"), local("../asset/fonts/GreycliffCF-Regular"),
    url("../asset/fonts/GreycliffCF-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../asset/fonts/GreycliffCF-Regular.woff2") format("woff2"),
    url("../asset/fonts/GreycliffCF-Regular.woff") format("woff"),
    url("../asset/fonts/GreycliffCF-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  font-family: "Greycliff CF", Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
}


